@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif;
}

.strong {
  background: #ffd266;
  color: black;
  border-radius: 5px;
}

.msic {
  font-size: 3rem;
  background: #4ad9e4;
  border-radius: 6px;
}

.main_image {
  height: 400px;
}

.main_carousel {
  height: 70vh !important;
  position: absolute;
}

.main_carousel_img {
  height: 60vh !important;
}

.carousel,
.slide,
.carousel .carousel-inner,
.carousel .carousel-item,
.carousel .carousel-item img,
.carousel .carousel-control {
  border-radius: 35px;
  overflow: hidden;
}

#header {
  width: 100%;
  height: 75vh;
  margin-top: 1vh;
}

.head {
  padding-top: 9vh;
  padding-left: 3vh;
}

.head-strong {
  color: #4ad9e4;
  background: whitesmoke;
}

#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
  height: 200px;
  padding-left: 10vw;
}

@-webkit-keyframes up-down {
  0% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@media screen and (min-width: 768) and (max-widht: 992px) {
  .home_head {
    padding-top: 15vh;
  }
}

@media screen and (max-width: 768px) {
  .main_carousel {
    width: 100%;
    padding-top: 0;
    padding-left: 0;
    height: 50vh !important;
  }

  .cards {
    border: 1px solid #4ad9e4 !important;
  }
  .home_head {
    padding-top: 10vh;
  }

  .carousel,
  .slide,
  .carousel .carousel-inner,
  .carousel .carousel-item,
  .carousel .carousel-item img,
  .carousel .carousel-control {
    border-radius: 0px;
    margin-top: 5vh;
  }
  .main_carousel_img {
    height: 30vh !important;
  }
}

.container_carousel2 {
  height: 400px;
}

.items2 img {
  height: 430px !important;
  width: 731.6px !important;
  border-radius: 10px !important;
}

@media screen and (max-width: 992px) {
  .container_carousel2 {
    margin-top: 15vh;
    padding-right: 0;
  }

  .carousel2__img {
    margin-right: 0;
  }
}

.typist {
  text-align: center;
}

.typist-heading {
  font-size: 2rem;
}

.typist-description {
  font-size: 1.3rem;
}

@media screen and (max-width: 992px) {
  .typist {
    margin-top: 150px;
  }
}

.line_ex {
  color: rgba(73, 63, 252, 1);
  margin-top: 3vh;
  font-family: "Satisfy", cursive !important;
  font-size: 1.8rem;
}

.head {
  text-align: center;
  margin-right: 2vw;
}

.about-main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  background: rgba(114, 124, 245, 0.09);
}

.container_about {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 3rem;
  margin-bottom: 4vh;
  margin-top: 1rem;
  text-align: center;
}

.container_about h2 {
  color: #4ad9e4;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 5px;
  padding-top: 4vh;
  border-radius: 8px;
}

.container_about h3 {
  color: #777;
  font-size: 2.5rem;
  font-weight: 400;
}

.container_about h1 {
  background: linear-gradient(
    90deg,
    rgba(110, 90, 253) 0%,
    rgba(73, 63, 252, 1) 100%
  );
  border-radius: 4px;
  color: white;
}

.image {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.container_about p {
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.6rem;
  margin-bottom: 2rem;
  color: grey;
}

.btn1 {
  display: inline-block;
  padding: 1rem 2rem;
  background: #4ad9e4;
  font-size: 1.5rem;
  text-decoration: none;
  margin-bottom: 4vh;
  color: #f6f6f6;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 10px;
  transition: background 0.5s;
}

.btn1:hover {
  background: rgba(73, 63, 252, 1);
  color: #f6f6f6;
}

.online_content {
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.6rem;
  margin-bottom: 2rem;
  color: #777;
}

.online_content_temp {
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.6rem;
  margin-bottom: 0.5rem;
  color: #777;
}

.online_content_benefits {
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.4rem;
  margin-bottom: 1rem;
  color: #777;
  list-style-type: circle;
  list-style-position: inside;
}

.about_home_img {
  height: 25vh;
  width: 12vw;
}

.home__dash {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 2vh;
}

.btn1_carousel:hover {
  background: rgba(73, 63, 252, 1);
  color: #f6f6f6;
}

.home__dash p {
  background: #4ad9e4;
  height: 5px;
  width: 80px;
}
.footer_testimonial_button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

@media screen and (max-width: 991px) {
  .ani__image {
    display: none;
  }
  .btn1_carousel {
    margin-left: 0;
    font-size: 1rem;
  }

  .footer_testimonial_button {
    display: contents;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .head {
    margin-bottom: 20vh;
    padding-top: 5vh;
    margin-bottom: 0;
  }

  .home__dash1 {
    visibility: hidden;
    display: none;
  }

  .line_ex {
    padding-top: 0;
    margin-top: 1vh;
  }

  .about_home_img {
    height: 160px;
    width: 120px;
  }
  .container_about p {
    font-size: 1.1rem;
  }
  .cont_details h3,
  h4,
  ul {
    font-size: 1.1rem;
  }
  .container_about h2 {
    font-size: 3rem;
    letter-spacing: 4px;
    font-weight: 600;
  }
  .container_about h3 {
    font-size: 1.7rem;
    font-weight: 350;
  }
  .line_ex {
    font-size: 1.2rem;
  }
  .btn1 {
    font-size: 1.1rem;
  }
}

.main-timeline {
  font-family: "Roboto", sans-serif;
  position: relative;
  background: rgba(114, 124, 245, 0.09);
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding-left: 2vw;
  padding-right: 2vw;
}
.main-timeline:after {
  content: "";
  display: block;
  clear: both;
}
.main-timeline .timeline {
  width: 60%;
  padding: 0 30px 0 0;
  margin: 20px 0;
  float: left;
}
.main-timeline .timeline-content {
  background-color: #536e79;
  min-height: 133px;
  padding: 30px 95px 30px 130px;
  display: block;
  position: relative;
  z-index: 1;
  text-decoration: none;
}
.main-timeline .timeline-content:before {
  content: "";
  background: #fff;
  border-radius: 100px 20px 20px 100px;
  position: absolute;
  left: 70px;
  top: 10px;
  bottom: 10px;
  right: -30px;
  z-index: -1;
}
.main-timeline .timeline-year {
  color: #fff;
  font-size: 45px;
  font-weight: 600;
  line-height: 45px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
  position: absolute;
  left: -15px;
  top: 50%;
}
.main-timeline .timeline-icon {
  color: #fff;
  background-color: #536e79;
  font-size: 60px;
  text-align: center;
  line-height: 100px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  right: -20px;
  top: 50%;
}
.main-timeline .title {
  color: #536e79;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 7px 0;
}
.main-timeline .description {
  color: #817f7f;
  font-size: 15px;
  margin: 0;
}
.main-timeline .timeline:nth-child(even) {
  padding: 0 0 0 30px;
  float: right;
}
.main-timeline .timeline:nth-child(even) .timeline-content {
  padding: 30px 130px 30px 95px;
}
.main-timeline .timeline:nth-child(even) .timeline-content:before {
  border-radius: 20px 100px 100px 20px;
  left: -30px;
  right: 70px;
}
.main-timeline .timeline:nth-child(even) .timeline-year {
  right: -15px;
  left: auto;
}
.main-timeline .timeline:nth-child(even) .timeline-icon {
  right: auto;
  left: -20px;
}
.main-timeline .timeline:nth-child(4n + 2) .timeline-content,
.main-timeline .timeline:nth-child(4n + 2) .timeline-icon {
  background-color: #00b8d4;
}
.main-timeline .timeline:nth-child(4n + 2) .title {
  color: #00b8d4;
}
.main-timeline .timeline:nth-child(4n + 3) .timeline-content,
.main-timeline .timeline:nth-child(4n + 3) .timeline-icon {
  background-color: #fea726;
}
.main-timeline .timeline:nth-child(4n + 3) .title {
  color: #fea726;
}
.main-timeline .timeline:nth-child(4n + 4) .timeline-content,
.main-timeline .timeline:nth-child(4n + 4) .timeline-icon {
  background-color: #01bfa5;
}
.main-timeline .timeline:nth-child(4n + 4) .title {
  color: #01bfa5;
}
@media screen and (max-width: 767px) {
  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even) {
    text-align: center;
    width: 100%;
    padding: 0 0 30px 0;
    margin: 0 0 30px;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    padding: 80px 25px;
  }
  .main-timeline .timeline-content:before,
  .main-timeline .timeline:nth-child(even) .timeline-content:before {
    border-radius: 50px 50px 20px 20px;
    bottom: -30px;
    top: 65px;
    left: 10px;
    right: 10px;
  }
  .main-timeline .timeline-year,
  .main-timeline .timeline:nth-child(even) .timeline-year {
    -webkit-transform: translateX(-50%) translateY(0);
            transform: translateX(-50%) translateY(0);
    top: 10px;
    left: 50%;
  }
  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    font-size: 50px;
    line-height: 85px;
    width: 85px;
    height: 85px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: auto;
    bottom: -20px;
    right: auto;
    left: 50%;
  }
}
@media screen and (max-width: 576px) {
  .main-timeline .title {
    font-size: 18px;
  }
}

.car_style {
  float: left;
}

.cont_owl_carousel {
  background: black;
}

.container_carousel {
  height: 75vh;
  width: 100%;
  margin: 0;
  margin-right: 0;
  padding: 0;
}

.container_carousel img {
  height: 18vh;
  width: 9vw !important;
}

.carousel_heading {
  padding-top: 4vh;
  margin-bottom: 3vh;
  color: #4ad9e4;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
}

.items p {
  max-width: 80%;
  color: white;
  padding-left: 20vw;
}

.items h2 {
  color: #ffb606;
}

.items span {
  color: whitesmoke;
}

@media screen and (max-width: 992px) {
  .container_carousel {
    padding-bottom: 790px;
  }

  .carousel_heading {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 1.2px;
    margin-bottom: 3vh;
  }

  .items img {
    height: 120px;
    width: 100px !important;
  }
}

.card-img-top {
  height: 30vh;
}

.img-rot {
  border-bottom: 2px solid #ccc !important;
}

.cards:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition: 400ms;
  box-shadow: 3px 3px 5px 6px #ccc;
}

@media screen and (max-width: 960px) {
  .cardd-content {
    height: 9vh !important;
  }

  .cards {
    box-shadow: 3px 3px 5px 6px #ccc;
  }
}

.form-group {
  padding: 1vh;
}

.btn {
  margin-top: 2vh;
}

.container_about_main {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 3rem;
  margin-bottom: 4vh;
  margin-top: 1rem;
  text-align: center;
}

.highlighted {
  background: linear-gradient(
    90deg,
    rgba(110, 90, 253) 0%,
    rgba(73, 63, 252, 1) 100%
  );
  padding: 0 4vw;
  color: white;
  border-radius: 4px;
}

.container_note {
  max-width: 1400px;
  text-align: center;
  padding: 2vh 3rem;
  margin: 0 auto;
  margin-top: 2vh;
  margin-bottom: 2vh;
  background: #e8ebf1;
  border-radius: 5px;
}

.container_study {
  max-width: 1400px;
  text-align: center;
  margin-top: 2vh;
  margin: 0 auto;
  padding: 0 3rem;
  margin-bottom: 2vh;
}

.container_study h2 {
  color: #4ad9e4;
  font-size: 3rem;
  letter-spacing: 2px;
  font-weight: 700;
  margin-bottom: 5vh;
}

.container_note h2 {
  color: #4ad9e4;
  font-size: 3rem;
  letter-spacing: 3.5px;
  font-weight: 700;
}

.container_about_main h2 {
  color: #4ad9e4;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 6px;
  margin-top: 4vh;
}

.container_about_main h3 {
  color: #777;
  font-size: 2.5rem;
  font-weight: 400;
}

.image_about {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.container_about_main p {
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.6rem;
  margin-bottom: 5rem;
  color: grey;
}

.heading {
  padding-top: 6vh;
}

.container_app {
  max-width: 1400px;
  text-align: center;
  margin-bottom: 4vh;
  margin-top: 1rem;
  margin: 0 auto;
  padding: 0 3rem;
}

.container_app h2 {
  color: #4ad9e4;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 3.5px;
}

.container_app p {
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.6rem;
  color: grey;
}

.about_app_p1 {
  margin-top: 3vh;
}

.about_app_p2 {
  margin-bottom: 4rem;
}

.container_key {
  max-width: 1400px;
  text-align: center;
  margin-bottom: 4vh;
  margin-top: 1rem;
  margin: 0 auto;
  padding: 0 3rem;
}

.container_key h2 {
  color: #4ad9e4;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 4px;
}

.container_note p {
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.6rem;
  color: grey;
}

.img_about {
  width: 300px;
  height: 320px;
}

@media screen and (max-width: 960px) {
  .img_about {
    width: 280px;
  }
  .container_about_main p {
    font-size: 1.1rem;
  }
  .container_app p {
    font-size: 1.1rem;
  }
  .container_note p {
    font-size: 1.1rem;
  }
  .container_about_main h2 {
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 3px;
  }
  .container_about_main h3 {
    font-size: 1.8rem;
    font-weight: 300;
  }
  .container_app h2 {
    font-weight: 600;
    font-size: 2.3rem;
    letter-spacing: 2.8px;
  }
  .container_key h2 {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 3px;
  }
  .container_study h2 {
    font-size: 2rem;
    letter-spacing: 1.2px;
    font-weight: 600;
  }
  .container_note h2 {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 3px;
  }
}

.container_key {
  margin-top: 20px;
  padding: 0;
}
.timeline {
  border-left: 3px solid #727cf5;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(114, 124, 245, 0.09);
  margin: 0 auto;
  letter-spacing: 0.2px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px;
  list-style: none;
  text-align: left;
  max-width: 80%;
}

@media (max-width: 767px) {
  .timeline {
    max-width: 98%;
    padding: 25px;
  }
}

.timeline h1 {
  font-weight: 300;
  font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
}

.timeline .event {
  border-bottom: 1px dashed #e8ebf1;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 767px) {
  .timeline .event {
    padding-top: 30px;
  }
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}

@media (max-width: 767px) {
  .timeline .event:before {
    left: 0px;
    text-align: left;
  }
}

.timeline .event:after {
  box-shadow: 0 0 0 3px #727cf5;
  left: -55.8px;
  background: #fff;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  content: "";
  top: 5px;
}

@media (max-width: 767px) {
  .timeline .event:after {
    left: -31.8px;
  }
}

.rtl .timeline {
  border-left: 0;
  text-align: right;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: 3px solid #727cf5;
}

.rtl .timeline .event::before {
  left: 0;
  right: -170px;
}

.rtl .timeline .event::after {
  left: 0;
  right: -55.8px;
}

.container_card {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-height: 105vh;
  font-family: sans-serif;
}
.box {
  width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 15px;
  margin: 0 auto;
}
.card_3 {
  position: relative;
  width: 300px;
  height: 380px;
  background: #fff;
  margin: 0 auto;
  margin-bottom: 4vh;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.card_3:before,
.card_3:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #4ad9e4;
  transition: 0.5s;
  z-index: -1;
}
.card_3:hover:before {
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}
.card_3:hover:after {
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}
.card_3 .imgBx {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background: #222;
  transition: 0.5s;
  z-index: 1;
}

.card_3:hover .imgBx {
  bottom: 80px;
}

.card_3 .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card_3 .details {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: 60px;
  text-align: center;
}

.card_3 .detailsFA {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: 60px;
  text-align: center;
}

.card_3 .details h2 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 20px;
  color: #777;
  text-transform: uppercase;
}

.card_3 .detailsFA h2 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  color: #777;
  text-transform: uppercase;
}

.card_3 .details h2 span {
  font-weight: 500;
  font-size: 16px;
  color: #f38695;
  display: block;
  margin-top: 5px;
}

.card_3 .detailsFA h2 span {
  font-weight: 500;
  font-size: 16px;
  color: #f38695;
  display: block;
  margin-top: 5px;
}

.NavbarItems {
  height: 80px;
  background: linear-gradient(
    90deg,
    rgba(110, 90, 253) 0%,
    rgba(73, 63, 252, 1) 100%
  );
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.2rem;
  -webkit-align-content: center;
          align-content: center;
}

.logo {
  border-radius: 50px;
  height: 50px;
  width: 60px;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}

.Navbar-logo {
  font-size: 20px;
  padding-left: 10px;
  color: white;
  margin-top: 11px;
}

.Nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  -webkit-justify-content: end;
          justify-content: end;
  margin-right: 2rem;
  margin-top: 10px;
}

.Nav-menu.active {
  margin-top: 10px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #6d76f7;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: block;
  text-align: center;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  background: #4ad9e4;
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
}

.nav_li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.fa-bars {
  color: white;
}

.nav-links-mobile:hover {
  background: white;
  color: #6568f4;
  transition: 250ms;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }
  .Nav-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 350px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-left: 0px;
    margin-top: 0;
  }
  .Nav-menu li a {
    padding: 6px;
  }
  .Nav-menu.active {
    background-color: #6668f4;
    margin-top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #7577fa;
    border-radius: 0;
  }
  .Navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
  }
  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #4ad9e4;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
  }
  .nav-links-mobile:hover {
    background: white;
    color: #6568f4;
    transition: 250ms;
  }
}

.footer_nav_item {
  box-sizing: border-box;
  padding-bottom: 0.5rem;
  text-decoration: none;
  color: #4ad9e4;
}

.footer_nav {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.footer_nav_item:hover {
  color: white;
}

.footer_contact {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.footer_contact_item {
  box-sizing: border-box;
  text-decoration: none;
  color: #4ad9e4;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.footer_contact_text {
  color: #4ad9e4;
}

.footer_contact_text:hover {
  color: white;
}

.img__footer {
  height: 45px;
  width: 50px;
}

.container_card4 {
  height: 100%;
  background: #3fb0ac;
  border-radius: 10px;
  margin-bottom: 8vh;
}

.wrapper {
  position: relative;
  padding-top: 5vh;
  top: 50%;
  left: 62%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  max-width: 650px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.card4 {
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-basis: 300px;
          flex-basis: 300px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  height: 100%;
  background: #fff;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  overflow: hidden;
  position: relative;
  border-bottom: 5vh;
}
.card4 img {
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
}
.descriptions {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(26, 185, 224, 0.7);
  width: 100%;
  height: 100%;
  transition: all 0.7s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
  -webkit-clip-path: circle(0% at 100% 100%);
          clip-path: circle(0% at 100% 100%);
}
.card4:hover .descriptions {
  left: 0px;
  transition: all 0.7s ease-in-out;
  -webkit-clip-path: circle(75%);
          clip-path: circle(75%);
}
.card4:hover {
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}
.card4:hover img {
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  -webkit-transform: scale(1.6) rotate(20deg);
          transform: scale(1.6) rotate(20deg);
  -webkit-filter: blur(3px);
          filter: blur(3px);
}
.card4 h1 {
  color: #ffb606;
  text-align: center;
  letter-spacing: 1px;
  padding-top: 15vh;
  margin: 0px;
  font-weight: 900;
}
.card4 p {
  line-height: 24px;
  padding-top: 2vh;
  height: 70%;
  text-align: center;
  font-size: 1.2rem;
  color: white;
}

.bottom_heading {
  padding: 1vh 3.3vw;
  width: 301px;
  border-radius: 0 0 5px 5px;
}

@media screen and (max-width: 991px) {
  .container_card4 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .row__wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .wrapper {
    left: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

@media screen and (max-width: 1199) and (min-width: 991) {
  .wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    left: 55%;
  }
}

.flex_app {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.app-btn_app {
  width: 45%;
  max-width: 160px;
  color: #fff;
  margin: 20px 10px;
  text-align: left;
  border-radius: 5px;
  text-decoration: none;
  font-family: "Lucida Grande", sans-serif;
  font-size: 10px;
  text-transform: uppercase;
}
.blu_app {
  background-color: #101010;
  transition: background-color 0.25s linear;
}
.blu_app:hover {
  background-color: #454545;
  color: #ffb606;
}
.btn_i {
  width: 20%;
  text-align: center;
  font-size: 28px;
  margin-right: 7px;
}
.big-txt {
  font-size: 17px;
  text-transform: capitalize;
}

.blu_app p {
  padding-top: 1.4vh;
}

.container_app_btn {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 0;
}

.carousel__dash h1 {
  background: #4ad9e4;
  height: 5px;
  width: 80px;
  margin-bottom: 1vh;
}

.carousel__dash {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

@media screen and (max-width: 992px) {
}

.card2-img-top {
  height: 30vh;
  width: 35vh;
  margin-left: 6.5vh;
  margin-top: 2vh;
}

.img-rot {
  border-bottom: 2px solid #ccc !important;
}

.cards:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition: 400ms;
  box-shadow: 3px 3px 5px 6px #ccc;
}

@media screen and (max-width: 960px) {
  .cardd-content {
    height: 9vh !important;
  }

  .cards {
    box-shadow: 3px 3px 5px 6px #ccc;
  }

  .img-rot {
    width: 270px;
    margin-left: 10vw;
    margin-top: 3vh;
  }
}

.iframe {
  border-radius: 15px;
  width: 30vw;
  margin-bottom: 4vh;
}

@media screen and (max-width: 960px) {
  .iframe {
    width: 100%;
  }
}


.NavbarItems {
  height: 80px;
  background: linear-gradient(
    90deg,
    rgba(110, 90, 253) 0%,
    rgba(73, 63, 252, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  align-content: center;
}

.logo {
  border-radius: 50px;
  height: 50px;
  width: 60px;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}

.Navbar-logo {
  font-size: 20px;
  padding-left: 10px;
  color: white;
  margin-top: 11px;
}

.Nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
  margin-top: 10px;
}

.Nav-menu.active {
  margin-top: 10px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #6d76f7;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: block;
  text-align: center;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  background: #4ad9e4;
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
}

.nav_li {
  display: flex;
  align-items: center;
}

.fa-bars {
  color: white;
}

.nav-links-mobile:hover {
  background: white;
  color: #6568f4;
  transition: 250ms;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }
  .Nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-left: 0px;
    margin-top: 0;
  }
  .Nav-menu li a {
    padding: 6px;
  }
  .Nav-menu.active {
    background-color: #6668f4;
    margin-top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #7577fa;
    border-radius: 0;
  }
  .Navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
  }
  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #4ad9e4;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
  }
  .nav-links-mobile:hover {
    background: white;
    color: #6568f4;
    transition: 250ms;
  }
}

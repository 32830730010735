.iframe {
  border-radius: 15px;
  width: 30vw;
  margin-bottom: 4vh;
}

@media screen and (max-width: 960px) {
  .iframe {
    width: 100%;
  }
}

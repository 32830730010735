.container_card4 {
  height: 100%;
  background: #3fb0ac;
  border-radius: 10px;
  margin-bottom: 8vh;
}

.wrapper {
  position: relative;
  padding-top: 5vh;
  top: 50%;
  left: 62%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 650px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card4 {
  flex: 1;
  flex-basis: 300px;
  flex-grow: 0;
  height: 100%;
  background: #fff;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  overflow: hidden;
  position: relative;
  border-bottom: 5vh;
}
.card4 img {
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
}
.descriptions {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(26, 185, 224, 0.7);
  width: 100%;
  height: 100%;
  transition: all 0.7s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
  clip-path: circle(0% at 100% 100%);
}
.card4:hover .descriptions {
  left: 0px;
  transition: all 0.7s ease-in-out;
  clip-path: circle(75%);
}
.card4:hover {
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  transform: scale(0.97);
}
.card4:hover img {
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  transform: scale(1.6) rotate(20deg);
  filter: blur(3px);
}
.card4 h1 {
  color: #ffb606;
  text-align: center;
  letter-spacing: 1px;
  padding-top: 15vh;
  margin: 0px;
  font-weight: 900;
}
.card4 p {
  line-height: 24px;
  padding-top: 2vh;
  height: 70%;
  text-align: center;
  font-size: 1.2rem;
  color: white;
}

.bottom_heading {
  padding: 1vh 3.3vw;
  width: 301px;
  border-radius: 0 0 5px 5px;
}

@media screen and (max-width: 991px) {
  .container_card4 {
    display: flex;
    justify-content: center;
  }

  .row__wrap {
    display: flex;
    justify-content: center;
  }

  .wrapper {
    left: 50%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1199) and (min-width: 991) {
  .wrapper {
    display: flex;
    justify-content: center;
    left: 55%;
  }
}

.container_carousel2 {
  height: 400px;
}

.items2 img {
  height: 430px !important;
  width: 731.6px !important;
  border-radius: 10px !important;
}

@media screen and (max-width: 992px) {
  .container_carousel2 {
    margin-top: 15vh;
    padding-right: 0;
  }

  .carousel2__img {
    margin-right: 0;
  }
}

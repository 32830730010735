.car_style {
  float: left;
}

.cont_owl_carousel {
  background: black;
}

.container_carousel {
  height: 75vh;
  width: 100%;
  margin: 0;
  margin-right: 0;
  padding: 0;
}

.container_carousel img {
  height: 18vh;
  width: 9vw !important;
}

.carousel_heading {
  padding-top: 4vh;
  margin-bottom: 3vh;
  color: #4ad9e4;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
}

.items p {
  max-width: 80%;
  color: white;
  padding-left: 20vw;
}

.items h2 {
  color: #ffb606;
}

.items span {
  color: whitesmoke;
}

@media screen and (max-width: 992px) {
  .container_carousel {
    padding-bottom: 790px;
  }

  .carousel_heading {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 1.2px;
    margin-bottom: 3vh;
  }

  .items img {
    height: 120px;
    width: 100px !important;
  }
}
